/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from "react";
// import "./styles/landing_page.css";
import "./styles/landing.css";
import "./styles/auth.css";
import { ReactComponent as NetLinkedInIcon } from "../../assets/symbols/net-linked-in.svg";
import { ReactComponent as NetTwitterIcon } from "../../assets/symbols/net-twitter.svg";
import Login_Page from "../Login/login_page";
import Verification_Page from "../Verification/verification_page";
import Verify_Name_Page from "../VerifyName/verify_name_page";
import Success_Page from "../Success/success_page";
import { useNavigate, useSearchParams } from "react-router-dom";
import { notification } from "antd";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import * as selectors from "../../selectors/selectors";
import * as actions from "../../actions/actions";
import { APP_VERSION, CREATE_LOYALTY_PAGE } from "../../utils/constants";
import AppButton from "../../Components/AppButton/app_button";
import axiosInstance from "../../services/axiosIntercept";
import { isAxiosError } from "axios";
import Organization_Name_Page from "../OrganizationName/organization_name";
import useAddAccountName from "../../Hooks/addAccountName";
import useGetOrganizations from "../../Hooks/getOrganizations";

function Landing_Page() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const isLoggedOut = useSelector(selectors.getIsLoggedOut);
  const isAuthenticated = useSelector(selectors.getIsAuthenticated);
  const addAccountName = useAddAccountName();
  const getOrganizations = useGetOrganizations();

  const [display, setDisplay] = useState(0);
  const [queryParams, setQueryParams] = useState(null);
  //const isAuthenticaed = useSelector(selectors.getIsAuthenticated);
  //Sandeep, Apple Login preperation
  const checkQueryParams = () => {
    const queryParams = new URLSearchParams(location.search);
    const queryParamEmail = queryParams.get("email");
    const queryParamOtp = queryParams.get("appleIDToken");
    const queryparamDeviceID = queryParams.get("deviceId");

    if (queryParams && queryparamDeviceID && queryParamEmail && queryParamOtp) {
      setQueryParams(queryParams);
      console.log(queryParamEmail, queryParamOtp, queryparamDeviceID);
      dispatch({ type: "SET_EMAIL", payload: queryParamEmail }); //Sandeep
      dispatch({ type: "SET_OTP", payload: queryParamOtp }); //Sandeep
      // Nov 27 setDisplay(3);
      return true; // Indicate that query parameters are found
    }
    return false; // Indicate that query parameters are not found
  };

  useEffect(() => {
    if (searchParams.get("email")) {
      setDisplay(1); // Open login screen automatically
    }
  }, [searchParams]);

  useEffect(() => {
    const Verify = async () => {
      try {
        const response = await axiosInstance.get(
          `${process.env.REACT_APP_API_URL}v3/auth/business/portal/verify`,
          {
            withCredentials: true,
            headers: {
              "api-key": process.env.REACT_APP_API_KEY,
            },
          },
        );

        console.log(response.data);

        if (response.data.authenticated) {
          dispatch(actions.setIsAuthenticated());
          notification.info({
            message: "You are already Logged in",
          });
          navigate("/dashboard", { replace: true });
        }
      } catch (error) {
        console.error("Error during data fetching: ", error);
      }
    };

    // Call the function to check query parameters
    if (!checkQueryParams()) {
      // If query parameters are not found, then verify authentication
      console.log("is logged out", isLoggedOut);
      if (isAuthenticated && !isLoggedOut) Verify();
    } else {
      //TODO: do login and initialize state, dologin hook  - Sandeep
      //navigate("/dashboard");
    }
  }, [navigate]);

  const handleLogin = async () => {
    axiosInstance
      .get(`${process.env.REACT_APP_API_URL}v3/auth/business/portal/verify`, {
        headers: {
          withCredentials: true,
          "api-key": process.env.REACT_APP_API_KEY,
        },
      })
      .then((res) => {
        console.log(res);
        if (res.data.authenticated) {
          dispatch(actions.setIsAuthenticated());
          navigate("/dashboard", { replace: true });
        } else {
          // Sandeep: This happens when the user is coming from clicking the login button
          // Call the function to check query parameters
          // if (!checkQueryParams()) {
          // If query parameters are not found, then verify authentication
          dispatch(actions.logout());
          dispatch(actions.setAuthIsLoading());
          setDisplay(1);
          //}
          // else{setDisplay(3);}
        }
      })
      .catch((err) => {
        dispatch(actions.setAuthError(err));

        if (isAxiosError(err)) {
          if (err.response?.status === 404 || err.response?.status === 401) {
            notification.error({
              duration: 5,
              message: "Server Error Occured",
              description:
                "Server is currently unreachable. Please try again in a moment.",
            });
            dispatch(actions.logout());
          }
        }
        console.error("Error while verifying: ", err);
      });
  };
  return (
    <>
      <div className="landing">
        <div className="landing__header">
          <div className="landing__header-wrap container">
            <div className="landing__brand">
              <div className="landing__logo">Meed</div>
              {/* <div className="landing__version">{APP_VERSION}</div> */}
            </div>
            <div className="landing__actions">
              <AppButton uniqueModifier="landing" onClick={handleLogin}>
                Log in
              </AppButton>
              <AppButton uniqueModifier="landing" onClick={handleLogin}>
                Sign-up for free
              </AppButton>
            </div>
          </div>
        </div>
        <div className="landing__body">
          <div className="landing__body-wrap container">
            <div className="landing__stage">
              <h1 className="landing__heading">
                <span className="landing__unlock">Supercharge</span>
                <span className="landing__power">Your Business</span>
                <span className="landing__business">with Loyalty</span>
              </h1>
              <div className="landing__description">
                meed’s Loyalty solutions drive customer engagement, increase
                retention, and transform your business into a community
                powerhouse
              </div>
            </div>
          </div>
        </div>
        <div className="landing__footer">
          <div className="landing__footer-wrap container">
            <div>
              <div className="landing__copyright">
                Copyright © 2024 Meed Limited
              </div>
            </div>
            <div className="landing__version">{APP_VERSION}</div>
            <div style={{ width: "70%" }}></div>
            <div className="landing__media">
              <a
                className="landing__social-network"
                href="https://twitter.com/meedloyalty"
                target="_blank"
              >
                <NetTwitterIcon className="landing__net" />
              </a>
              <a
                className="landing__social-network"
                href="https://www.linkedin.com/company/meedloyalty/"
                target="_blank"
              >
                <NetLinkedInIcon className="landing__net" />
              </a>
            </div>
          </div>
          <div className="landing__footer-bar"></div>
        </div>
      </div>
      {display === 1 && (
        <Login_Page
          handleClick={() => {
            setDisplay(2);
          }}
          prevPage={() => {
            setDisplay(0);
          }}
        />
      )}
      {display === 2 && (
        <Verification_Page
          prevPage={() => {
            setDisplay(1);
          }}
          handleClick={() => {
            setDisplay(3);
          }}
          handleNewAccount={() => {
            setDisplay(5);
          }}
          movetoSuccess={() => {
            navigate("/dashboard", { replace: true });
          }}
        />
      )}
      {display === 3 && (
        <Verify_Name_Page
          prevPage={() => {
            setDisplay(2);
          }}
          handleNewAccount={() => {
            setDisplay(5);
          }}
          handleClick={() => {
            navigate(`/dashboard/${CREATE_LOYALTY_PAGE}`, {
              replace: true,
            });
          }}
        />
      )}
      {display === 4 && <Success_Page handleClick={() => {}} />}
      {display === 5 && (
        <Organization_Name_Page
          onSave={async (name) => {
            // Handle the saving here, not inside the Organization_Name_Page component.
            dispatch(actions.setAccountName(name));
            await addAccountName(name);

            // Refresh list of organizations after saving
            getOrganizations();

            navigate(`/dashboard/${CREATE_LOYALTY_PAGE}`);
          }}
          onCancel={() => {
            setDisplay(3);
          }}
        />
      )}
    </>
  );
}

export default Landing_Page;
