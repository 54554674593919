import React, { useState } from "react";
import "./premium.css";
import AppButton from "../AppButton/app_button";
import PaypalMonthlySubscriptionButton from "../PaypalMonthlySubscriptionButton/paypal_monthly_subscription_button";
import PaypalAnnualSubscriptionButton from "../PaypalMonthlySubscriptionButton copy/paypal_annual_subscription_button";
import { Switch } from "antd";
/**
 *
 * @param {object} props
 * @param {() => void} props.freeOnClickFunction
 * @param {()=>void} props.payment
 * @param {import("../../types").Business} props.business
 * @returns {import("react").ReactNode}
 */
function Premium({ freeOnClickFunction, business, payment }) {
  const [isYearly, setIsYearly] = useState(true);
  const openURL = () => {
    window.open(
      "https://www.meedloyalty.com/blank",
      "_blank",
      "noopener noreferrer",
    );
  };
  return (
    <div className="premium">
      <h1 className="premium__heading">Go Premium</h1>
      <div className="premium__toggle">
        <Switch
          checked={isYearly}
          onChange={setIsYearly}
          checkedChildren="Yearly"
          unCheckedChildren="Monthly"
          style={{ backgroundColor: "#333333" }}
        />
      </div>
      <div className="premium__body">
        <div className="premium__plan">
          <h2 className="premium__plan-heading">Starter</h2>
          <div className="premium__plan-body">
            <div className="premium__variant">
              <div className="premium__price">
                <div className="premium__price-panel">
                  <div
                    className="premium__dollar"
                    style={{ marginLeft: "38px" }}
                  >
                    $
                  </div>
                  <div className="premium__value">0</div>
                  <div className="premium__period">per month & forever</div>
                </div>
              </div>
              <div className="premium__submit">
                <AppButton variant="join" onClick={freeOnClickFunction}>
                  Join for free
                </AppButton>
              </div>
              <div className="premium__list">
                <div className="premium__item">1 x loyalty program</div>
                <div className="premium__item">1 x voucher campaign</div>
                <div className="premium__item">1 x location</div>
                <div className="premium__item">1 x seat</div>
                <div className="premium__item">Unlimited members</div>
                <div className="premium__item">Performance dashboard</div>
                <div className="premium__item">No setup fees</div>
                <div className="premium__item">Unlimited Apple Pass</div>
                <div className="premium__item">Unlimited Google Pass</div>
                <div className="premium__item">
                  Auto-generate QR Code poster
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="premium__plan premium__plan--has-frame">
          <h2 className="premium__plan-heading">Premium</h2>
          <div className="premium__plan-body">
            {isYearly ? (
              <div className="premium__variant">
                <div className="premium__price premium__price--has-best-value">
                  <div className="premium__price-panel">
                    <div className="premium__dollar">$</div>
                    <div className="premium__value">390</div>
                    <div className="premium__period">yearly</div>
                  </div>
                  <div className="premium__best-value">Best Value</div>
                </div>
                <div className="premium__submit">
                  {/* <button className="button button--join" type="button">
                              Purchase
                            </button> */}
                  <PaypalAnnualSubscriptionButton
                    data={business}
                    payment={(data) => payment(data)}
                  />
                </div>
                <div className="premium__list">
                  <div className="premium__item">2 x loyalty programs</div>
                  <div className="premium__item">20x locations</div>
                  <div className="premium__item">
                    Unlimited voucher campaigns
                  </div>
                  <div className="premium__item">Unlimited seats</div>
                  <div className="premium__item">Unlimited members</div>
                  <div className="premium__item">Unlimited vouchers</div>
                  <div className="premium__item">Performance dashboard</div>
                  <div className="premium__item">No setup fees</div>
                  <div className="premium__item">Unlimited Apple Pass</div>
                  <div className="premium__item">Unlimited Google Pass</div>
                  <div className="premium__item">
                    Auto-generate QR Code poster
                  </div>
                  <div className="premium__item">Members email Opt-in</div>
                </div>
              </div>
            ) : (
              <div className="premium__variant">
                <div className="premium__price">
                  <div className="premium__price-panel">
                    <div
                      className="premium__dollar"
                      style={{ marginLeft: "16px" }}
                    >
                      $
                    </div>
                    <div className="premium__value">39</div>
                    <div className="premium__period">monthly</div>
                  </div>
                </div>
                <div className="premium__submit">
                  {/* <button className="button button--join" type="button">
                  Buy now
                </button> */}
                  <PaypalMonthlySubscriptionButton
                    data={business}
                    payment={(data) => payment(data)}
                  />
                </div>
                <div className="premium__list">
                  <div className="premium__item">2 x loyalty programs</div>
                  <div className="premium__item">20x locations</div>
                  <div className="premium__item">
                    Unlimited voucher campaigns
                  </div>
                  <div className="premium__item">Unlimited members</div>
                  <div className="premium__item">Unlimited vouchers</div>
                  <div className="premium__item">Performance dashboard</div>
                  <div className="premium__item">No setup fees</div>
                  <div className="premium__item">Unlimited Apple Pass</div>
                  <div className="premium__item">Unlimited Google Pass</div>
                  <div className="premium__item">
                    Auto-generate QR Code poster
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="premium__plan">
          <h2 className="premium__plan-heading">Enterprise</h2>
          <div className="premium__plan-body">
            <div className="premium__variant">
              <div className="premium__price">
                <div className="premium__price-panel">
                  <div
                    className="premium__value"
                    style={{ fontSize: 50, marginTop: 0 }}
                  >
                    Custom
                  </div>
                  <div className="premium__period" style={{ fontSize: 12 }}>
                    Reach out for more information
                  </div>
                </div>
              </div>
              <div className="premium__submit">
                <AppButton variant="join" onClick={openURL}>
                  Contact Us
                </AppButton>
              </div>
              <div className="premium__list">
                <div className="premium__item">
                  If you have a custom request, don't hesitate to contact us.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Premium;
