import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./manage_voucher.css";
import useGetVouchers from "../../Hooks/getVouchers";
import useGetVerified from "../../Hooks/getVerified";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../actions/actions";
import * as selectors from "../../selectors/selectors";
import {
  calculateDuration,
  handleActionNotification,
  showTierVoucherLimitReachedWarning,
} from "../../utils/utils";
import { VoucherType } from "../../utils/constants";
import { extractCurrencyCode } from "../../utils/helpers";
import Deal from "../../Components/Deal/deal";
import Promo from "../../Components/Promo/promo";
import { DateTime } from "luxon";
import { updateVoucherStatus } from "../../services/voucherService";
import Add from "../../Components/Add/add";
import GuidedTour from "../../Components/GuidedTour";
import Filter from "../../Components/Filter/filter";
import Pagination from "../../Components/Pagination/pagination";
import LoadingSpinner from "../../Components/LoadingSpinner/loading_spinner";
import { notification, Switch } from "antd";
import useGenerateStamps from "../../Hooks/generateStamps";
import useReissueDigitalVoucher from "../../Hooks/reissueDigitalVoucher";
import classNames from "classnames";
import useLimitCheck from "../../Hooks/getLimitCheck";
import Business from "../../Components/Business/business";
import useCheckRestart from "../../Hooks/checkRestart";
import useActivateWelcomeVoucher from "../../Hooks/activatWelcomeVoucher";

/**
 *
 * @param {object} props
 * @param {() => void} props.handleCreate
 * @param {(voucher: import("../../types").Voucher) => void} props.handleEdit
 * @param {(voucher: import("../../types").Voucher, canRestart: boolean) => void} props.handleRestart
 * @returns {import("react").ReactNode}
 */
function Manage_Voucher({ handleCreate, handleEdit, handleRestart }) {
  const dispatch = useDispatch();
  const uuid = useSelector(selectors.getUuid);
  const accountUuid = useSelector(selectors.getAccountUuid);
  const getVouchers = useGetVouchers(accountUuid);
  const getVerified = useGetVerified();
  const getLimitCheck = useLimitCheck();
  const checkRestart = useCheckRestart();

  const isAuthenticated = useSelector(selectors.getIsAuthenticated);
  const isLoggedOut = useSelector(selectors.getIsLoggedOut);

  const vouchers = useSelector(selectors.getVouchers);
  const isLoadingVouchers = useSelector(selectors.getIsLoadingVouchers);
  const programName = useSelector(selectors.getSelectedProgramName);
  const programLocation = useSelector(selectors.getSelectedProgramLocation);
  const businessImageName = useSelector(selectors.getBusinessImageName);
  const businessBrandColor = useSelector(selectors.getBusinessBrandColor);
  const selectedLp = useSelector(selectors.getSelectedLp);
  const selected_lp_uuid = useSelector(selectors.getSelectedLpUuid);
  const LimitCheck = useSelector(selectors.getLimitcheck);
  const checkRestartData = useSelector(selectors.getCheckRestart);
  const generateStamps = useGenerateStamps();
  const reissueDigitalVoucher = useReissueDigitalVoucher();
  const activateWelcomeVoucher = useActivateWelcomeVoucher();

  useEffect(() => {
    getLimitCheck();
    checkRestart();
  }, []);

  const programVoucherLimit = useMemo(
    () =>
      LimitCheck?.voucherCampaignLimits?.find(
        (x) => x.programUuid === selected_lp_uuid,
      ),
    [LimitCheck, selected_lp_uuid],
  );

  useEffect(() => {
    // Clear store //TODO can make a hook to clear storage.
    dispatch(actions.setCashVoucher(null));
    dispatch(actions.setDiscountVoucher(null));
    dispatch(actions.setEventPass(null));
    dispatch(actions.setEventAddress(null));
    dispatch(actions.setGiveaway(null));
    dispatch(actions.setSpecialAddress(null));

    getVouchers(selectedLp);
  }, [selectedLp]);

  useEffect(() => {
    if (!isAuthenticated && !isLoggedOut) getVerified();
  }, [isAuthenticated, isLoggedOut, getVerified]);

  const onChangeActiveStatus = useCallback(
    async (
      /** @type {import("../../types").Voucher} */ voucher,
      /** @type {boolean} */ status,
    ) => {
      handleActionNotification(
        async () => {
          try {
            // Only update if provided status is different from the stored status
            await updateVoucherStatus(voucher.code, status);
            generateStamps(selectedLp, true, selected_lp_uuid);
            reissueDigitalVoucher(selected_lp_uuid);

            dispatch(
              actions.setVoucher(voucher.code, {
                isValid: status,
              }),
            );

            // Refresh LimitCheck
            getLimitCheck();

            notification.success({
              duration: 5,
              message: status
                ? "Selected voucher campaign has been activated."
                : "Selected voucher campaign has been deactivated.",
            });
          } catch (e) {
            notification.error({
              message: "Error while updating voucher campaign status",
              description: `Error while updating voucher campaign status. Please try again.`,
            });
          }
        },
        status
          ? "This will activate the voucher campaign and enable all existing vouchers of this campaign."
          : "This will deactivate the voucher campaign and disable all existing vouchers of this campaign.",
        "Cancel",
        "Yes",
      );
    },
    [dispatch],
  );

  /*   const showData = (data) => {
                                    switch (data.voucherTypeId) {
                                      case VoucherType.DISCOUNT:
                                        return `${data.valueDescription} OFF \n${data.productServiceDescription}`;
                                      case VoucherType.CASH:
                                        return `${data.value} ${extractCurrencyCode(
                                          data.valueDescription,
                                        )} OFF\n${data.productServiceDescription}`;
                                      case VoucherType.GIVEAWAY:
                                        return `${data.productServiceDescription}`;
                                      case VoucherType.EVENT_PASS:
                                        return `${data.eventCode}`;
                                      default:
                                        return "NULL";
                                    }
                                  }; */

  /* useEffect(() => {
                                    async function fetchData() {
                                      setIsLoading(true); // Show loading spinner
                                      setData([]); // Clear existing data
                                      setIsEmpty(false);
          
                                      try {
                                        if (!isAuthenticated && !isLoggedOut) await getVerified();
          
                                        // Fetch vouchers based on the selected loyalty program
                                        const program_id = selectedLp;
                                        if (program_id === null) {
                                          // Handle null program_id case
                                          setIsEmpty(true);
                                        } else {
                                          const fetchedVouchers = await getVouchers(program_id);
                                          if (fetchedVouchers && fetchedVouchers.length > 0) {
                                            setData(fetchedVouchers);
                                            setIsEmpty(false);
                                          } else {
                                            setIsEmpty(true);
                                          }
                                        }
                                      } catch (error) {
                                        console.error("Error during data fetching: ", error);
                                        setIsEmpty(true);
                                      } finally {
                                        setIsLoading(false);
                                      }
                                    }
          
                                    fetchData();
                                  }, [selectedLp, isAuthenticated, isLoggedOut, getVouchers, getVerified]); */

  const getValue = (data) => {
    switch (data.voucherTypeId) {
      case VoucherType.DISCOUNT:
        return (
          <>
            {data.valueDescription}
            {<br />}{" "}
          </>
        );
      case VoucherType.CASH:
        return (
          <>
            {data.value} {extractCurrencyCode(data.valueDescription)} OFF{" "}
            {<br />}{" "}
          </>
        );
      case VoucherType.GIVEAWAY:
        return <>Giveaway</>;
      case VoucherType.EVENT_PASS:
        return <>Event Pass</>;
      default:
        return "NULL";
    }
  };

  const getTitle = (data) => {
    switch (data.voucherTypeId) {
      case VoucherType.DISCOUNT:
        return <>{data.productServiceDescription}</>;
      case VoucherType.CASH:
        return <>{data.productServiceDescription}</>;
      case VoucherType.GIVEAWAY:
        return <>{data.productServiceDescription}</>;
      case VoucherType.EVENT_PASS:
        return <>{data.eventAddress}</>;
      default:
        return "NULL";
    }
  };

  const voucherStatusFilters = useMemo(() => {
    const now = DateTime.now();
    return {
      all: `All (${vouchers?.length || 0})`,
      active: `Active (${(vouchers || []).reduce(
        (total, item) =>
          total +
          (item.isValid && DateTime.fromISO(item.expiryDate) >= now ? 1 : 0),
        0,
      )})`,
      inactive: `Inactive (${(vouchers || []).reduce(
        (total, item) =>
          total +
          (!item.isValid && DateTime.fromISO(item.expiryDate) >= now ? 1 : 0),
        0,
      )})`,
      expired: `Expired (${(vouchers || []).reduce(
        (total, item) =>
          total + (DateTime.fromISO(item.expiryDate) < now ? 1 : 0),
        0,
      )})`,
    };
  }, [vouchers]);
  const [activeVoucherStatusFilter, setActiveVoucherStatusFilter] = useState(
    /** @type {string} */ ("active"),
  );
  const [currentPage, setCurrentPage] = useState(1);
  const filteredVouchers = useMemo(() => {
    switch (activeVoucherStatusFilter) {
      case "active": {
        const now = DateTime.now();
        return (
          vouchers?.filter(
            (item) => item.isValid && DateTime.fromISO(item.expiryDate) >= now,
          ) || []
        );
      }
      case "inactive": {
        const now = DateTime.now();
        return (
          vouchers?.filter(
            (item) => !item.isValid && DateTime.fromISO(item.expiryDate) >= now,
          ) || []
        );
      }
      case "expired": {
        const now = DateTime.now();
        return (
          vouchers?.filter((item) => DateTime.fromISO(item.expiryDate) < now) ||
          []
        );
      }
      case "all":
      default:
        return vouchers || [];
    }
  }, [vouchers, activeVoucherStatusFilter]);

  const handleCreateVoucher = () => {
    if (programVoucherLimit?.alert) {
      showTierVoucherLimitReachedWarning();
    } else {
      handleCreate();
    }
  };

  console.log("programVoucherLimit", uuid, programVoucherLimit);
  const handleSocial = (
    businessName,
    businessLogo,
    programUuid,
    brandColor,
    heroBannerLogo,
  ) => {
    const queryParams = new URLSearchParams({
      businessName,
      businessLogo,
      programUuid,
      brandColor,
      heroBannerLogo,
    }).toString();

    const url = `/social-voucher?${queryParams}`; // Replace with the actual path to your PdfPage component
    window.open(url, "_blank");
  };

  if (isLoadingVouchers) {
    return <LoadingSpinner />;
  }

  function determineCanRestart(checkRestartData, content) {
    if (checkRestartData?.canRestart) {
      return true;
    } else {
      if (
        content.autoVoucher &&
        checkRestartData?.voucher?.includes("autoVoucher")
      ) {
        return false;
      } else if (
        content.referralRewardVoucher &&
        checkRestartData?.voucher?.includes("referralVoucher")
      ) {
        return false;
      } else {
        return true;
      }
    }
  }

  return (
    <div className="voucher container">
      <div className="voucher__heading">
        <h1 className="title">Manage your campaigns</h1>
      </div>
      <div className="voucher__filter">
        <Filter
          items={voucherStatusFilters}
          selectedItem={activeVoucherStatusFilter}
          onRefresh={async () => {
            await getVouchers(selectedLp);
          }}
          onSelectItem={(key) => {
            setActiveVoucherStatusFilter(key);
          }}
        />
      </div>

      <div className="voucher__body">
        <Add
          onClick={handleCreateVoucher}
          title="Edit live voucher campaigns here."
          className={classNames(programVoucherLimit?.alert && "add--disabled")}
        >
          Create a new
          <br />
          campaign
        </Add>
        {filteredVouchers
          ?.slice((currentPage - 1) * 7, currentPage * 7)
          .map((content) => {
            return (
              <Promo
                key={content.id}
                issued={content.digitalVouchersIssued}
                redeemed={content.redeemedDigitalVoucherCount}
                isEditable={
                  content.expiryDate
                    ? DateTime.fromISO(content.expiryDate) >
                      DateTime.now().startOf("day")
                    : true
                }
                isStarted={content.startDate}
                isActive={content.isValid}
                isExpired={
                  DateTime.fromISO(content.expiryDate) < DateTime.now()
                }
                isAutovoucher={content.autoVoucher}
                isReferralVoucher={content.referralRewardVoucher}
                canRestart={determineCanRestart(checkRestartData, content)}
                voucherLimitExceeded={programVoucherLimit?.alert}
                onChangeActiveStatus={(status) => {
                  console.log("promo", status && programVoucherLimit?.alert);
                  if (status && programVoucherLimit?.alert) {
                    showTierVoucherLimitReachedWarning();
                  } else {
                    onChangeActiveStatus(content, status);
                  }
                }}
                onRestartClick={() => {
                  if (programVoucherLimit?.alert) {
                    showTierVoucherLimitReachedWarning();
                  } else {
                    handleRestart(
                      content,
                      determineCanRestart(checkRestartData, content),
                    );
                  }
                }}
                onEditClick={() => {
                  handleEdit(content);
                }}
                socialHandler={(e) => {
                  e.stopPropagation();
                  console.log(content);
                  handleSocial(
                    content.campaignName,
                    businessImageName,
                    content.code,
                    businessBrandColor,
                    content.imageId,
                  );
                }}
              >
                <Deal
                  background={businessBrandColor}
                  brandLogo={`${process.env.REACT_APP_CDN_URL}${businessImageName}.jpg`}
                  campaignPicture={`${process.env.REACT_APP_CDN_URL}${content.imageId}.jpg`}
                  brandName={programName}
                  offerTitle={getTitle(content)}
                  offerValue={getValue(content)}
                  location={programLocation}
                  duration={`${calculateDuration(
                    new Date(),
                    new Date(content.startDate),
                    new Date(content.expiryDate),
                  )}`}
                />
                <div className="wel_voucher">
                  <div className="wel_voucher_label">
                    Use as Welcome Voucher
                  </div>
                  <div className="wel_voucher_widget">
                    <Switch
                      disabled={
                        content.autoVoucher ||
                        content.referralRewardVoucher ||
                        !content.isValid
                      }
                      checked={content.welcomeVoucherCampaign}
                      style={{
                        background:
                          content.welcomeVoucherCampaign && "var(--orange)",
                        borderColor:
                          content.welcomeVoucherCampaign && "var(--orange)",
                      }}
                      onChange={(checked) => {
                        if (!content.welcomeVoucherCampaign && checked) {
                          // Reset all other vouchers
                          vouchers?.forEach((voucher) => {
                            if (voucher.code !== content.code) {
                              dispatch(
                                actions.setVoucher(voucher.code, {
                                  welcomeVoucherCampaign: false,
                                }),
                              );
                            }
                          });
                          // Activate the current voucher
                          dispatch(
                            actions.setVoucher(content.code, {
                              welcomeVoucherCampaign: true,
                            }),
                          );
                          activateWelcomeVoucher(
                            selected_lp_uuid,
                            content.code,
                          );
                        }
                        // Prevent switching off if already a welcome voucher
                      }}
                    />
                  </div>
                </div>
              </Promo>
            );
          })}
      </div>
      <div className="voucher__pagination">
        <Pagination
          currentPage={currentPage}
          itemsPerPage={7}
          onChangePage={(i) => setCurrentPage(i)}
          totalItems={filteredVouchers.length}
        />
      </div>
      <GuidedTour
        id="manageVouchers"
        steps={[
          {
            content:
              "Switch between loyalty programs, if you have more than one.",
            title: "Step 1",
            target: ".sidebar__photo",
            placementBeacon: "right",
            placement: "right",
          },
          {
            content: "Create a new voucher campaign.",
            title: "Step 2",
            target: ".add",
            placementBeacon: "top",
            placement: "right",
          },
          {
            content: "Instantly pause any campaign that is currently live.",
            title: "Step 3",
            target: ".activate-voucher-btn",
            placementBeacon: "top",
            placement: "top",
          },
          {
            content: "Edit live voucher campaigns here.",
            title: "Step 4",
            target: ".edit-voucher-btn",
            placementBeacon: "top",
            placement: "top",
          },
        ]}
      />
    </div>
  );
}

export default Manage_Voucher;
