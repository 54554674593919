import React, { useEffect, useMemo, useState } from "react";
import styles from "../CreateLoyaltyMain/create_loyalty_main.module.css";
import "./dashboard.css";
import VoucherOkSrc from "../../assets/icons/loyalty.svg";
import VoucherTotalSrc from "../../assets/icons/loyalty.svg";
import VoucherUsedSrc from "../../assets/icons/loyalty.svg";
import { ReactComponent as CalendarLargeIcon } from "../../assets/symbols/calendar-large.svg";
import { ReactComponent as PlusCircleIcon } from "../../assets/symbols/plus-circle.svg";
import { ReactComponent as HugeArrowUpIcon } from "../../assets/symbols/huge-arrow-up.svg";
import { ReactComponent as HugeArrowRightIcon } from "../../assets/symbols/huge-arrow-right.svg";

import useGetVerified from "../../Hooks/getVerified";
import { useSelector } from "react-redux";
import * as selectors from "../../selectors/selectors";
import { useDispatch } from "react-redux";
import { getOverviewAction } from "../../actions/overviewActions";
import Title from "../../Components/Title/title";
import { DateTime } from "luxon";
import GuidedTour from "../../Components/GuidedTour";
import LoadingSpinner from "../../Components/LoadingSpinner/loading_spinner";
import useLimitCheck from "../../Hooks/getLimitCheck";
import { showTierVoucherLimitReachedWarning } from "../../utils/utils";
import classNames from "classnames";

function Create_loyalty_main({ handleClick }) {
  const getVerified = useGetVerified();
  const getLimitCheck = useLimitCheck();
  const dispatch = useDispatch();
  const uuid = useSelector(selectors.getUuid);
  const LimitCheck = useSelector(selectors.getLimitcheck);
  const accountUuid = useSelector(selectors.getAccountUuid);
  const selected_lp_uuid = useSelector(selectors.getSelectedLpUuid);

  const isAuthenticated = useSelector(selectors.getIsAuthenticated);
  const isLoggedOut = useSelector(selectors.getIsLoggedOut);
  const isLoadingOverviewData = useSelector(selectors.getIsLoadingOverviewData);
  const overview_data = useSelector(selectors.getOverviewData);

  const [memberLimit, setMemberLimit] = useState(0);

  useEffect(() => {
    getLimitCheck();
  }, []);

  const programVoucherLimit = useMemo(
    () =>
      LimitCheck?.voucherCampaignLimits?.find(
        (x) => x.programUuid === selected_lp_uuid,
      ),
    [LimitCheck, selected_lp_uuid],
  );

  // useEffect(() => {
  // Calculate memberLimit
  // const calculatedMemberLimit =
  //   (LimitCheck?.memberAlert.overallMembers /
  //     LimitCheck?.memberAlert.memberLimitOverall) *
  //   100;

  // Limiting the value to two decimal places
  // const roundedMemberLimit = parseFloat(calculatedMemberLimit.toFixed(0));

  // Set memberLimit state
  // setMemberLimit(roundedMemberLimit);
  // }, [LimitCheck]);

  useEffect(() => {
    if (!isAuthenticated && !isLoggedOut) getVerified();
    // console.log("uuid", uuid);
    // Dispatch getOverview only if uuid is available and overview_data is not yet loaded
    /*  if (uuid && (!overview_data || !overview_data.Vouchers)) {
      getOverview(uuid);
    } */
    //if (!overview_data) getOverview();
  }, [getVerified, isAuthenticated, isLoggedOut]);

  useEffect(() => {
    if (isAuthenticated && accountUuid) {
      // @ts-ignore
      dispatch(getOverviewAction(accountUuid));
    } else {
      console.error("UUID is not available for getOverview action");
    }
  }, [isAuthenticated, accountUuid]);

  /** @type {DateTime} */
  const currentDateTime = useMemo(() => {
    return DateTime.now();
  }, []);

  if (isLoadingOverviewData) {
    return <LoadingSpinner />;
  }

  return (
    <div className="dashboard container">
      <h1 className="dashboard__heading">
        <Title>Overview</Title>
        {/* {LimitCheck && LimitCheck?.memberAlert.alert && (
          <div className="dashboard__tieralert">
            You have reached {memberLimit}% of your plan limit(s). Please
            consider upgrading your plan.
          </div>
        )} */}
      </h1>
      <div className="dashboard__summary">
        <div
          className="dashboard__stat"
          title="The total number of unredeemed vouchers your customers could redeem today."
        >
          <img
            className="dashboard__illustration"
            src={VoucherOkSrc}
            style={{ width: "80px" }}
            width="100"
            height="100"
            alt=""
          />
          <div className="dashboard__param">Active Vouchers</div>
          <div className="dashboard__value">
            {overview_data?.Vouchers.Active || 0}
          </div>
        </div>
        <div
          className="dashboard__stat"
          title="The total number of vouchers that have been redeemed to date."
        >
          <img
            className="dashboard__illustration"
            src={VoucherTotalSrc}
            style={{ width: "80px" }}
            width="100"
            height="100"
            alt=""
          />
          <div className="dashboard__param">Total Redeemed</div>
          <div className="dashboard__value">
            {overview_data?.Vouchers.TotalRedeemed || 0}
          </div>
        </div>
        <div
          className="dashboard__stat"
          title="The total number of vouchers you have created since you started your loyalty program."
        >
          <img
            className="dashboard__illustration"
            src={VoucherUsedSrc}
            style={{ width: "80px" }}
            width="100"
            height="100"
            alt=""
          />
          <div className="dashboard__param">Total Vouchers</div>
          <div className="dashboard__value">
            {overview_data?.Vouchers.Total || 0}
          </div>
        </div>
      </div>
      <div className="dashboard__panel">
        <CalendarLargeIcon className="dashboard__calendar" />
        <a className="dashboard__date-time" name="fake-link">
          {currentDateTime.toFormat("MMM d, yyyy, EEE")}
        </a>
        <button
          className={classNames(
            "dashboard__create",
            programVoucherLimit?.alert && "dashboard__create--disabled",
          )}
          onClick={() => {
            if (programVoucherLimit?.alert) {
              showTierVoucherLimitReachedWarning();
            } else {
              handleClick();
            }
          }}
        >
          <PlusCircleIcon className="dashboard__plus" />
          Create new campaign
        </button>
      </div>
      <div className="dashboard__body">
        <div className="dashboard__item">
          <div className="dashboard__sub-heading">
            Vouchers
            <br /> Redeemed
          </div>
          <div className="dashboard__growth">
            {overview_data?.Vouchers.RedeemedYesterday || 0}
          </div>
          <div className="dashboard__time-frame">Yesterday</div>
          {/* Can be "Up", "Right" or "Down": */}
          <HugeArrowUpIcon className="dashboard__arrow" />
        </div>
        <div className="dashboard__item">
          <div className="dashboard__sub-heading">
            New
            <br /> Members
          </div>
          <div className="dashboard__growth">
            {overview_data?.Memberships.NewMembersYesterday || 0}
          </div>
          <div className="dashboard__time-frame">Yesterday</div>
          {/* Can be "Up", "Right" or "Down": */}
          <HugeArrowRightIcon className="dashboard__arrow" />
        </div>
      </div>
      <GuidedTour
        id="overview"
        steps={[
          {
            content:
              "The total number of unredeemed vouchers your customers could redeem today.",
            title: "Step 1",
            target: ".dashboard__stat:nth-child(1)",
          },
          {
            content:
              "The total number of vouchers that have been redeemed to date.",
            title: "Step 2",
            target: ".dashboard__stat:nth-child(2)",
          },
          {
            content:
              "The total number of vouchers you have created since you started your loyalty program.",
            title: "Step 3",
            target: ".dashboard__stat:nth-child(3)",
          },
        ]}
      />
    </div>
  );
}

export default Create_loyalty_main;
