import React, { useState } from "react";
import "./paypal_annual_subscription_button.css";
import useUpdatePaymentApproval from "../../Hooks/updatePaymentApproval";
import { PayPalButtons } from "@paypal/react-paypal-js";
import { Modal } from "antd";

/**
 * @param {object} props
 * @param {()=>void} props.payment
 * @param {import("../../types").Business} props.data
 * @returns {import("react").ReactNode}
 */
function PaypalAnnualSubscriptionButton({ data, payment }) {
  const updatedPaymentApproval = useUpdatePaymentApproval();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const openModal = (message) => {
    setModalMessage(message);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };
  return (
    <>
      <PayPalButtons
        style={{
          shape: "rect",
          color: "black",
          layout: "horizontal",
          label: "subscribe",
          tagline: false,
        }}
        createSubscription={(data, actions) => {
          return actions.subscription.create({
            plan_id: "P-2AL6951657002231YMZDS76Y",
          });
        }}
        onApprove={async (data, actions) => {
          alert(`Subscription ID: ${data.subscriptionID}`);
          await updatedPaymentApproval({
            data,
            success: true,
            planId: "P-2AL6951657002231YMZDS76Y",
          });
          payment(2);
          openModal("Payment Successful");
        }}
        onError={async (err) => {
          console.error("Err", err);
          await updatedPaymentApproval({
            data,
            success: false,
            planId: "P-2AL6951657002231YMZDS76Y",
          });
          openModal("Payment Failed");
        }}
        onCancel={async (canc) => {
          console.log("Canceled", canc);
          await updatedPaymentApproval({
            data,
            success: false,
            planId: "P-2AL6951657002231YMZDS76Y",
          });
          openModal("Payment Cancelled");
        }}
      />
      <Modal
        title="Payment Status"
        visible={modalIsOpen}
        onOk={closeModal}
        onCancel={closeModal}
      >
        <p>{modalMessage}</p>
      </Modal>
    </>
  );
}

export default PaypalAnnualSubscriptionButton;
