export const SET_DISCOUNT_VOUCHER = "SET_DISCOUNT_VOUCHER";
export const SET_CASH_VOUCHER = "SET_CASH_VOUCHER";
export const SET_GIVEAWAY = "SET_GIVEAWAY";
export const SET_SPECIAL_ADDRESS = "SET_SPECIAL_ADDRESS";
export const SET_EVENT_PASS = "SET_EVENT_PASS";
export const SET_EVENT_ADDRESS = "SET_EVENT_ADDRESS";
export const SET_CURRENCY = "SET_CURRENCY";
export const RESET = "RESET";

export const SET_NAME = "SET_NAME";
export const SET_CURRENT_SUBSCRIPTION = "SET_CURRENT_SUBSCRIPTION";
export const SET_MERCHANT_USER_ROLE = "SET_MERCHANT_USER_ROLE";
export const SET_SUPER_ADMIN = "SET_SUPER_ADMIN";
export const SET_EMAIL = "SET_EMAIL";
export const SET_ID = "SET_ID";
export const SET_UUID = "SET_UUID";
export const SET_PROFILE_IMAGE_ID = "SET_PROFILE_IMAGE_ID";
export const SET_OTP = "SET_OTP";
export const SET_LIMIT_CHECK = "SET_LIMIT_CHECK";
export const SET_CHECK_RESTART = "SET_CHECK_RESTART";
export const SET_ANALYTICS = "SET_ANALYTICS";
export const SET_ACCOUNT_UUID_TO_LOG_IN = "SET_ACCOUNT_UUID_TO_LOG_IN";

export const SET_IS_LOADING_OVERVIEW_DATA = "SET_IS_LOADING_OVERVIEW_DATA";
export const SET_OVERVIEW_DATA = "SET_OVERVIEW_DATA";
export const SET_COLOUR = "SET_COLOUR";
export const SET_PATTERN = "SET_PATTERN";
export const SET_PROGRAM = "SET_PROGRAM";
export const SET_IS_LOADING_PROGRAM = "SET_IS_LOADING_PROGRAM";
export const SET_VOUCHERS = "SET_VOUCHERS";
export const SET_VOUCHER = "SET_VOUCHER";
export const SET_IS_LOADING_VOUCHERS = "SET_IS_LOADING_VOUCHERS";
export const SET_INDUSTRIES = "SET_INDUSTRIES";
export const SET_SELECTED_COLOR = "SET_SELECTED_COLOR";
export const SET_SELECTED_PROGRAM_NAME = "SET_SELECTED_PROGRAM_NAME";
export const SET_BUSINESS_IMAGE_NAME = "SET_BUSINESS_IMAGE_NAME";
export const SET_SELECTED_LP = "SET_SELECTED_LP";
export const SET_SELECTED_LP_UUID = "SET_SELECTED_LP_UUID";
export const SET_IMPERSONATING_MERCHANT_ID = "SET_IMPERSONATING_MERCHANT_ID";
export const LOGOUT = "LOGOUT";
export const SET_AUTH_IS_LOADING = "SET_AUTH_LOADING";
export const SET_AUTH_ERROR = "SET_AUTH_ERROR";
export const SET_IS_AUTHENTICATED = "SET_AUTHENTICATED";
export const SET_SELECTED_PROGRAM_LOCATION = "SET_SELECTED_PROGRAM_LOCATION";
export const SET_ACCOUNTS = "SET_ACCOUNTS";
export const ADD_ACCOUNT = "ADD_ACCOUNT";
